<template>
  <div class="login">
    <div class="login-bg hidden-sm-and-down"></div>
    <!-- 左半部 -->
    <el-row class="login-body" type="flex">
      <el-col :xs="{
        span: 24,
        offset: 0
      }" :sm="{
        span: 24,
        offset: 0
      }" :md="{
        span: 20,
        offset: 2
      }" :lg="{
        span: 16,
        offset: 4
      }" :xl="{
        span: 12,
        offset: 6
      }" class="login-content">
        <el-row class="login-form">
          <el-col :xs="0" :sm="1" :md="8" :lg="12" :xl="12" class="login-form-right hidden-sm-and-down">
            <h2 class="qjc-c-white qjc-fts-48 qjc-ftw-500">Medin AI Health System
            </h2>
          </el-col>
          <el-col :xs="24" :sm="23" :md="16" :lg="12" :xl="12">
            <div class="right qjc-relative">
              <div class="right_label">

                <el-form class="" :model="login" :rules="rules" ref="login">
                  <el-form-item prop="account" :show-message="false">
                    <el-input v-model.trim="login.account" class="account account-input"
                      placeholder="Please enter your account name" @focus="clearFormError2">
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password" :show-message="false">
                    <el-input v-model.trim="login.password" class="password" type="password" @focus="clearFormError2"
                      :maxlength="40" placeholder="Please enter your login password">
                    </el-input>
                  </el-form-item>
                  <div class="form-error">
                    <p class="error">{{ formError2 }}</p>
                  </div>



                  <el-form-item class="agree" prop="agree" :show-message="false">
                    <el-checkbox v-model="login.agree" @change="clearFormError2"></el-checkbox>
                    <b class="qjc-fts-12 qjc-c-dark"><label @click="login.agree = !login.agree">I have read and
                        agree</label><span @click="agreementShow = true" class="qjc-c-primary qjc-ftw-n">《Medin Health
                        AI
                        Health Screening System Backend
                        Service Agreement》</span></b>
                  </el-form-item>

                  <el-form-item>
                    <el-button @click="submit" :loading="loging"
                      class="login-btn qjc-wid-100 qjc-fts-18 qjc-ftw-500">sign
                      in</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>

          </el-col>
        </el-row>
      </el-col>

    </el-row>


    <!-- 右半部 -->
    <!-- @keyup.enter="submit" -->

    <!-- 米因大数据体检平台数据后台服务协议 -->
    <el-dialog :visible.sync="agreementShow" title="米因健康大数据体检平台企业后台服务协议" class="agreement">
      <ul class="qjc-fts-12">
        <li>
          <!--                        <h6>一、特别提示</h6>-->
          <p class="agreement-special">
            本协议约定米因智慧医疗（MEDINAI&WISEMEDICALLIMITED，以下简称米因）与用户之间关于“米因健康大数据体检平台企业后台”（以下简称“后台”）的权利义务。</p>
          <p>“用户”是指登录、使用本后台的机构，“操作员”是指机构用户指定的、负相关岗位责任的该机构僱员。</p>
          <p>
            本协议可由米因随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可随时查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用后台，用户继续使用后台将被视为接受修改后的协议。
          </p>
        </li>
        <li>
          <!--                        <h6>二、账号注册</h6>-->
          <p>1.在米因的认可和允许下，用户有权在本后台进行以下的操作：
            <br /><span>（1）个人客户的会员注册、套餐开通；</span>
            <br /><span>（2）查询套餐开通记录；</span>
            <br /><span>（3）查询、调取个人客户的健康大数据体检报告；</span>
            <br /><span>（4）调取个人客户的预约信息；</span>
          </p>
          <p>2.用户在为个人客户进行会员注册和套餐开通操作时，需要得到个人客户的许可，个人客户应当在自主、自愿的前提下将手机接收到的验证码以及个人信息告知用户，并由操作员负责录入并完成相关操作；</p>
          <p>3.操作员有义务准确填写个人客户的姓名、年龄、性别、出生日期等个人信息，并遵照个人客户的意愿选择开通相应的套餐；</p>
          <p>4.用户清楚了解套餐的开通记录将作为米因与用户之间财务结算的主要凭证；</p>
          <p>5.用户有义务保护个人客户的数据隐私，禁止滥用、洩露个人客户的数据，包括但不限于：
            <br /><span>（1）在非客户要求、非必要的情况下随意查阅、打印、分享个人客户的健康大数据体检报告；</span>
            <br /><span>（2）将个人客户的个人信息、健康大数据体检报告内容等洩露给无关人员和机构；</span>
          </p>
          <p>
            6.因用户及其操作员的不正当使用而导致用户自身的一切损失，米因概不负责；因用户及其操作员的不正当使用而导致米因遭受损失的，米因有权依法进行追究；因用户及其操作员的不正当使用而导致任何违法、违纪、隐私洩露、公共危害等问题发生的，米因有权立刻中止与用户的合作，并在必要时配合有关部门、机构的调查与处理，一切后果由用户承担。
          </p>
          <p>
            7、用户应充分告知客户并获得用户的认可，米因大数据健康分析及评估产品为基于常规人体数据所做的大数据个人健康风险分析及预测，不涉及任何需专业医疗资质的诊断及处方，所有分析报告、结果及方案等输出内容均为健康咨询相关的参考内容与建议，客户须根据自身情况采用，或进一步咨询专业的医疗人员。
          </p>
          <p>8、客户通过常规流程接收到大数据分析及预测等报告相关内容时，即视为产品已完成交付，且客户已消费完毕，米因及相关服务平台均不接受任何退款要求及相关申诉。</p>

          <p></p>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
// 验证引入
import {
  isPhone,
  pwdReg
} from '@/utils'

import {
  sendsms,
  login,
  getDoctorOrder
} from '@/utils/axios'

export default {
  name: 'login',
  data () {
    // 验证

    // 密码
    const pwdVerify = (rule, value, callback) => {
      if (pwdReg(value)) {
        callback();
      } else {
        callback(new Error('Passwords must consist of two or more characters'));
      }
    }

    // 手机号
    const phoneVerify = (rule, value, callback) => {
      if (isPhone(value)) {
        callback();
      } else {
        callback(new Error('Incorrect mobile phone number format'));
      }
    }

    // 阅读协议
    const agreeVerify = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error(
          'Please read and tick《Medin Health AI Health Screening System Backend Service Agreement》'
        ));
      }
    }

    return {
      pageMinHeight: window.innerHeight || document.documentElement.clientHeight || document.body
        .clientHeight, // 页面最小高度为浏览器视口高度

      // 登录表单
      login: {
        account: '', // 账号
        password: '', // 密码
        agree: false // 是否接受米因协议 
      },
      // 验证
      rules: {
        account: [{
          required: true,
          message: 'The account number cannot be empty',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: 'Password cannot be empty',
          trigger: 'blur'
        },
          // { validator: pwdVerify, trigger: 'blur' }
        ],
        agree: [{
          validator: agreeVerify,
          trigger: 'change'
        }]
      },
      agreementShow: false, //米因协议是否显示

      // 登录
      loging: false, // 正在登录
      formError2: "",
      fromDx2: ""
    }
  },

  beforeCreate () {
    // 已经登录 返回首页
    if (this.$store.state.isLogin) {
      this.$router.replace('/')
    }
  },

  methods: {

    clearFormError2 () {
      this.formError2 = ""
    },
    //登录
    submit () {
      if (!this.login.account) {
        this.formError2 = 'Please enter your account name'
        return
      }
      if (!this.login.password) {
        this.formError2 = 'Please enter your password'
        return
      }
      if (!this.login.agree) {
        this.formError2 = 'Please read and agree to the agreement'
        return
      }
      this.$refs.login.validate((isSuc, obj) => {
        if (isSuc) {
          this.loging = true; //正在请求 按钮loading

          login({
            account: this.login.account,
            password: this.login.password,
            type: 2
          }).then(res => {

            if (res.code == 200) {
              this.loging = false;
              let data = JSON.parse(window.atob(res.data))
              this.$store.dispatch('login', data.access_token);
              // 存储用户信息
              localStorage.setItem('userInfo', JSON.stringify(data));
              localStorage.setItem("mobile", data.username)
              sessionStorage.setItem('theLanguage', 'en')


              // sessionStorage.clear()
              let toUrl = this.$route.query.toUrl;
              // .catch为vue3改为promise后可能报错
              this.$router.replace(toUrl ? toUrl : '/').catch(err => {

              });
            } else {
              this.loging = false;
              this.formError2 = res.msg
              // this.$myAlert({
              // 	type: 'fail',
              // 	content: res.msg
              // });
            }

          });
        }
      });
    },

    //必须输入数字
    phoneMustNumber (value) { //手机号
      this.login.phone = value.replace(/\D/g, '');
    },
    codeMustNumber (value) { //验证码
      this.login.code = value.replace(/\D/g, '');
    }
  }
}
</script>

<style scoped>
/* safari光标错位 */
.right .el-input>>>.el-input__inner {
  height: 42px;
  font-size: 14px;
  padding: 0;
  border-radius: 8px;
}

.account>>>.el-input__inner,
.password>>>.el-input__inner {
  border: none;
}

.account,
.password {
  border: none;
  border-bottom: 1px solid #00000040;
  border-radius: 0;
  margin-top: 18px;
}

.account:hover,
.password:hover {
  border-bottom: 1px solid #6883fb;
}


.phone>>>.el-input__inner,
.code>>>.el-input__inner {
  /* padding-left: 74px; */
}

/* 协议 */
.agreement>>>.el-dialog__header {
  background-color: #e7e9ee;
}

.agreement>>>.el-dialog__title {
  font-size: 16px;
}

.right>>>.el-tabs__nav-wrap::after {
  background-color: #fff;
}

.right>>>.el-tabs__header {
  /* margin-bottom: 30px; */
}

.right>>>.el-tabs__item {
  font-size: 18px;
}

.right>>>.el-tabs__content {
  overflow: inherit;
}
</style>
<style lang="scss" scoped>
.login {
  width: 100%;
  min-width: 375px;
  height: 100vh;


  .login-bg {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: url(../../assets/images/login_bg_en.png) no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }


  .login-body {
    position: relative;
    width: 100%;
    height: 100%;
  }



  .right {
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    .right_label {
      width: 100%;
      background-color: #fff;
      padding: 32px;
      border-radius: 10px;
    }

    h2 {
      // margin-bottom: 28px;
      padding: 0.2rem;
    }

    h4 {
      margin: 24px 0 12px;
    }

    .tabs_wrap {
      background-color: #fff;
    }

    .el-form {
      // top: 50%;
      // left: 50%;
      width: 100%;
      padding: 0 0.4rem;
      background-color: #fff;
      // transform: translate(-50%,-50%);

      .el-form-item {
        margin-bottom: 2px;

        .el-input {
          font-size: 14px;

          .prefix {
            padding: 0 8px;
            line-height: 36px;
          }

          span {
            padding-right: 24px;
            line-height: 36px;
          }

          .send-code {
            cursor: pointer;
          }

          .sending {
            color: #CCCCCC;
            cursor: not-allowed;
          }
        }

        &.agree {
          padding: 6px 0;

          b {
            font-weight: 300;
            margin-left: 6px;
          }

          span {
            cursor: pointer;
          }
        }

        .login-btn {
          height: 48px;
        }
      }
    }
  }

  .form-error {
    width: 100%;
    height: 42px;
    font-size: 14px;

    .error {
      color: #ff2e0b;
    }
  }

  .agreement {
    ul {
      color: #777f8f;
      line-height: 24px;
    }
  }
}

.login-content {
  display: flex;
  align-items: center;
}

.login-form {
  background-color: #fff;
  height: fit-content;
  position: relative;
  height: 500px;
  overflow: hidden;
  margin: auto;

  .login-form-right {
    position: relative;
    height: 100%;
    overflow: hidden;
    background-image: url(../../assets/images/login-right-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
    padding: 20px;
  }
}
</style>